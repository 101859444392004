import {useStaticQuery, graphql} from 'gatsby'

const useInterviewcoachData = () => {
  const data = useStaticQuery(graphql`
    query InterviewcoachData {
      allContentfulInterviewCompletionPage {
        nodes {
          title
          copy {
            copy
          }
          jobSearchButtonLink
          processedImage {
            childImageSharp {
              gatsbyImageData
            }
          }
          interviewButtonLink
        }
      }
    }
  `)

  return data.allContentfulInterviewCompletionPage.nodes[0]
}

export default useInterviewcoachData
